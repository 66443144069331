import React, { useRef, useState } from 'react';
import Layout from '../components/layout'
import Intro from '../components/branding/intro'
import Icons from '../components/icons'
import Cursor from '../components/cursor'
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'
import Portfolio from '../components/portfolio'


function BrandingIndex() {


	const [modalVisible, setIsShowing] = useState(false);

	function updateStateModal() {
		setIsShowing(!modalVisible);
	}

	const containerRef = useRef(null)

	return (
		<>
			<LocomotiveScrollProvider options={{ smooth: true, tablet: { smooth: true }, smartphone: { smooth: false }}} watch={[]} containerRef={containerRef}>
				<main id="top" data-scroll-container ref={containerRef}>
					<Layout type="branding" left="branding" visible={modalVisible} showreelModal={updateStateModal} >
						<Intro />
						<Portfolio />
						<Icons />
					</Layout>

				</main>
			</LocomotiveScrollProvider>
			<Cursor />
		</>

	)
}


export default BrandingIndex

