import React, { useEffect, useRef, useState } from 'react';
import { StaticQuery, graphql } from "gatsby"

import ImageHero from './branding/image-hero';
import arrowRight from "../images/arrow-right.svg"
import arrowRightWhite from "../images/arrow-right-white.svg"
import { isMobile } from "react-device-detect";
import { gsap, Linear } from 'gsap/all';

import { useLocomotiveScroll } from 'react-locomotive-scroll'
import { Link } from "gatsby"


export default () => (
	<StaticQuery
		query={graphql`
		query BrandingIndexQuery {
		 withOrder: allContentfulBranding(
				filter: {order: {ne: null}}
				sort: {fields: order, order: DESC}
			) {
				edges {
					node {
						title
						slug
						tags
						tipologia
						location
						folder
						order
						location
					}
				}
			}
			withoutOrder: allContentfulBranding(
				filter: {order: {eq: null}}
				sort: {fields: order, order: DESC}
			) {
				edges {
					node {
						title
						slug
						tags
						tipologia
						location
						folder
						order
						location
					}
				}
			}
			}
				`}

		render={data => {

			const posts = [...data.withOrder.edges, ...data.withoutOrder.edges];

			const refs = useRef([]);

			const [left, setLeft] = useState(0);

			const { scroll } = useLocomotiveScroll()

			const fadeUp = useRef([]);
			const gif = useRef([]);
			const fadeUpMarque = useRef([]);
			const fadeUpMarque2 = useRef([]);
			const marquee = useRef([]);
			const marquee2 = useRef([]);
			const itemDetailsPos = useRef([]);
			const itemDetailsPos2 = useRef([]);
			const firstText = useRef([]);
			const maxWidth = useRef();
			const arrowPos = useRef([]);
			const arrowPos2 = useRef([]);
			const textPos2 = useRef([]);
			const tagsPos = useRef([]);
			const tagsPos2 = useRef([]);


			const handleMouseOver = (i) => {
				fadeUp.current[i].setAttribute('transform', 'translate(' + left + ',-165)');
				fadeUpMarque.current[i].setAttribute('transform', 'translate(' + left + ',165)');
				fadeUpMarque2.current[i].setAttribute('transform', 'translate(' + left + ',165)');
				gif.current[i].style.opacity = 1;
				itemDetailsPos2.current[i].style.opacity = 1;
				timeline.play(i * 2);
				timeline.play(i * 2 + 1);

			};

			const animateMobile = (i, inView) => {
				if (inView) {
					handleMouseOver(i);
				} else {
					handleMouseLeave(i);
				}
			};


			const handleMouseLeave = (i) => {
				fadeUp.current[i].setAttribute('transform', 'translate(' + left + ',0)');
				fadeUpMarque.current[i].setAttribute('transform', 'translate(' + left + ',330)');
				fadeUpMarque2.current[i].setAttribute('transform', 'translate(' + left + ',330)');
				gif.current[i].style.opacity = 0;
				itemDetailsPos2.current[i].style.opacity = 0;
				timeline.pause(i * 2);
				timeline.pause(i * 2 + 1);
			};


			const tlArray = [];
			const timeline = {
				set: function (el) {

					// set a default rate, the higher the value, the faster it is
					let rate = 160;
					// get the width of the element
					let distance = 3000;
					// get the duration of the animation
					let time = distance / rate;
					// get the parent of the element
					let container = el;

					var tl = new gsap.timeline({
						paused: true,
						repeat: -1,
					});

					// animation code here
					tl.to(container, time, {
						x: "-" + distance,
						ease: Linear.easeNone,
					});
					// finally to the array
					tlArray.push(tl);
				},
				play: function (i) {
					tlArray[i].restart();
				},
				pause: function (i) {
					tlArray[i].pause();
				},
			};

			for (var i = 0;i < marquee.current.length;i++) {

				timeline.set(marquee.current[i]);
				timeline.set(marquee2.current[i]);

			}


			useEffect(() => {

				let left = maxWidth.current.getBoundingClientRect().x;
				setLeft(left + 1);

				for (var i = 0;i < marquee.current.length;i++) {
					var bbox = firstText.current[i].getBBox();
					var leftArrowPos = Math.round(bbox.width + 20);
					var leftTagsPos = Math.round(bbox.width + 70 + 30);

					textPos2.current[i].style.transform = "translate3d(" + left + "px, 0px, 0px)";
					arrowPos.current[i].style.transform = "translate3d(" + leftArrowPos + "px, 0px, 0px)";
					arrowPos2.current[i].style.transform = "translate3d(" + Math.round(left + leftArrowPos) + "px, 0px, 0px)";
					tagsPos.current[i].style.transform = "translate3d(" + leftTagsPos + "px, 0px, 0px)";
					tagsPos2.current[i].style.transform = "translate3d(" + Math.round(left + leftTagsPos) + "px, 0px, 0px)";
					fadeUp.current[i].setAttribute('transform', 'translate(' + left + ',0)');
					fadeUpMarque.current[i].setAttribute('transform', 'translate(' + left + ',330)');
					itemDetailsPos.current[i].style.transform = "translate3d(" + left + "px, 0px, 0px)";

				}


				if (isMobile && scroll) {

					scroll.on('call', func => {
						if (func == "animate") {
							var inView = scroll.scroll.callObj.inView;
							var i;
							if (scroll.scroll.callObj.target) {
								i = scroll.scroll.callObj.target.attributes.index.value;
							} else if (scroll.scroll.callObj.targetEl) {
								i = scroll.scroll.callObj.targetEl.attributes.index.value;
							}

							animateMobile(i, inView);
						}
					});
				}
			}, [scroll])


			return (
				<>
					{posts.map(({ node }, i) => {
						return (
							<Link key={i} index={i} ref={refs.current[i]} id={node.folder} to={`/portfolio/${node.slug}/`} 
							className={`col-xs-12 p-relative portfolio-item ball-js p-0 ${i == 0 ? " line-top" : ""}`}
							onMouseOver={() => handleMouseOver(i)} onMouseLeave={() => handleMouseLeave(i)} 
							data-scroll-call="animate" data-scroll data-scroll-offset="40%, 60%" data-scroll-repeat>
								<div className="max-width item-details p-relative">
									<span className="number">#{('000' + (i + 1)).slice(-3)}</span>
									<span ref={el => maxWidth.current = el} className="location">{node.location}</span>
								</div>

								<div className='marquee-branding'>
									<div className="marquee branding p-relative">
										<svg class="mask" xmlns="http://www.w3.org/3300/svg">
											<defs>
												<mask id="mask-inside-text">
													<rect x="50%" transform="translate(-243,0)" width={486} height={160} y="82.5" fill="#fff">
													</rect>
												</mask>
												<mask id="mask-outside-text">
													<rect width="100%" height={160} y="82.5" fill="#fff" />
												</mask>
												<mask id="mask-inside-text-bottom">
													<rect x="50%" transform="translate(-243,0)" width={486} height={160} y="165" fill="#fff" />
												</mask>
											</defs>


											<g className="marquee-title" mask="url(#mask-outside-text)" >
												<text className="theCount fade-up-js " y={165} dy={34} ref={el => fadeUp.current[i] = el}>
													<tspan>{node.title}</tspan>
												</text>
											</g>


											<g mask="url(#mask-outside-text)">
												<g className="marquee-title" ref={el => marquee.current[i] = el}>
													<text className="theCountDown fade-up-js" dy={34} transform="translate(0,330)" ref={el => fadeUpMarque.current[i] = el}>
														<tspan>{node.title} </tspan>
														<tspan>{node.title} </tspan>
														<tspan>{node.title} </tspan>
														<tspan>{node.title} </tspan>
														<tspan>{node.title} </tspan>
														<tspan>{node.title} </tspan>
														<tspan>{node.title} </tspan>
														<tspan>{node.title} </tspan>
													</text>
												</g>
											</g>

											<g ref={el => itemDetailsPos.current[i] = el} className="item-details-text">
												<g ref={el => firstText.current[i] = el} >
													<text y={270}>
														<tspan>
															{node.tipologia}
														</tspan>
													</text>
												</g>
												<g ref={el => arrowPos.current[i] = el} >
													<image y={258} href={arrowRight} style={{ width: "60px", height: "16px" }} />
												</g>
												<g ref={el => tagsPos.current[i] = el} >
													<text y={270}>
														<tspan>
															{node.tags}
														</tspan>
													</text>
												</g>
											</g>
										</svg>

										<div className="gif" ref={el => gif.current[i] = el}>
											<ImageHero foldername={node.folder} />
										</div>

										<svg class="mask2" xmlns="http://www.w3.org/3300/svg" >
											<g className="inside-text" mask="url(#mask-inside-text)">
												<g className="marquee-title-mask" ref={el => marquee2.current[i] = el}>
													<text ref={el => fadeUpMarque2.current[i] = el} className="theCountDown theCount-mask fade-up-js" dy={34} transform="translate(0,330)">
														<tspan>{node.title} </tspan>
														<tspan>{node.title} </tspan>
														<tspan>{node.title} </tspan>
														<tspan>{node.title} </tspan>
														<tspan>{node.title} </tspan>
														<tspan>{node.title} </tspan>
														<tspan>{node.title} </tspan>
														<tspan>{node.title} </tspan>
													</text>
												</g>
											</g>
											<g ref={el => itemDetailsPos2.current[i] = el} className="item-details-text" mask="url(#mask-inside-text-bottom)">
												<g ref={el => textPos2.current[i] = el} >
													<text y={270}>
														<tspan>
															{node.tipologia}
														</tspan>
													</text>
												</g>
												<g ref={el => arrowPos2.current[i] = el} >
													<image y={258} href={arrowRightWhite} style={{ width: "60px", height: "16px" }} />
												</g>
												<g ref={el => tagsPos2.current[i] = el} >
													<text y={270}>
														<tspan>
															{node.tags}
														</tspan>
													</text>
												</g>
											</g>
										</svg>

									</div>
								</div>
								<span className="line-branding"></span>
							</Link>
						)
					})}
				</>
			)
		}}
	/>
)